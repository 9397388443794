body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root, #main{
  height: 100%;
}

#navbar{
  height: 90px;
}

#view{
  height: calc(100% - 90px) !important;
}

.bg-light-blue{
  background-color: #f0f0f0 !important;
}

@media (min-width: 768px) {
  .w-md-25{
    width: 25% !important;
  }
}
.h-adjust{
  height: 100%;
}

.form-loading{
  width: 40px;
}
.page-loading{
  width: 200px;
}
.image-placehodler{
  width: 100%;
  max-width: 100%;
  height: 245px;
  max-height: 245px;
  border: 1px solid grey;
}
.img-contain{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.grid-template{
  display: grid;
  height: 100%;
  grid-template-columns: 75% 25%;
}
.main-panel{
  grid-column: 1;
  height: 100%;
}
.side-panel{
  grid-column: 2;
  height: 100%;
  background-color: #f0f0f0 !important;
}
.tab, .inner-tab{
  padding: 5px 15px;
  border: 0;
  margin-right: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
  height: 5%;
}
.tab-active, .tab:hover{
  background-color: #f0f0f0;
}
.tab-content{
  background-color: #f0f0f0;
  margin-right: 15px;
  height: 95%;
}
.inner-tab{
  background-color: #f0f0f0;
}
.active-inner-tab, .inner-tab:hover{
  background-color: #e0e0e0;
}
.inner-tab-content{
  background-color: #e0e0e0;
  min-height: 500px;
}
.supplier-table{
  overflow-y: scroll;
  height: 250px;
  min-height: 250px;
  max-height: 250px;
}
#image-set{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
#image-container{
  width: 60%;
  height: 470px;
  overflow-y: scroll;
  background-color: #d0d0d0;
}
#image-controller{
  width: 30%;
}
.thumbnail{
  width: 25%;
  padding: 2.5%;
}
.art-ops{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#print-area { 
  visibility: hidden; 
  display: none;
}

.tag-btn{
  padding-top: 0;
  padding-bottom: 0;
}

#user-table{
  overflow-y: scroll;
  height: 350px;
  min-height: 350px;
  max-height: 350px;
}

.main-logo{
  width: 200px;
}
.main-title{
  padding: 30px;
}

.login-flex{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.main-left{
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main-right{
  width: 20%;
  text-align: justify;
  padding: 30px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.main-form{
  width: 90%;
}

.ops-supplier button{
  margin-right: 10px !important;
}

@media only print {
  body { 
    visibility: hidden; 
  }
  .main-panel{
    display: none;
  }
  #print-area { 
    visibility: visible; 
    display: block;
  }

  #print-area td {
    padding: 5px 10px;
  }

  #print-inv-num {
    font-size: 14pt;
  }

  .print-container{
    border: 2px solid black;
    padding: 10px;
    margin-bottom: 5px;
  }

  .print-checkboxes{
    height: 120px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .print-checkboxes-high{
    height: 150px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .print-flex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .print-flex-left{
    width: 400px;
  }
  .print-flex-right{
    width: 320px;
  }
}